import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import {graphql} from "gatsby";


import SolutionsModule from '../components/SolutionsModule'
import PageTemplate from '../components/PageTemplate'

import model_mbl from '../images/mission/faa-model-mbl.png'
import employee_photo from '../images/mission/faa-model.png'

// query to load agency data from excel file
// NOTE: graphql queries must exist in a 'page' file...they will not work within a component file
// unless you use a StaticQuery component which has a different signature

//** NOTE: we are loading the locations data separately from the locations column of the second
//** sheet so that users can determine order of locations from the excel doc
export const IndexQuery = graphql`
  query {
    allAgencydataXlsxSheet1 {
      edges {
        node {
          name
          categories
          disciplines
          locations
          headline
          link
          logo
          contact_name
          contact_email
        }
      }
    }
      allAgencydataXlsxSheet2 {
          edges {
              node {
                  locations
              }
          }
      }
  }
`

const GlobalStyles = createGlobalStyle`
  body {
    background-color: #001b33;
  }
`

const PageContent = styled.div`
  position: relative;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    min-height: 770px;
    padding-top: 4rem;
    z-index: 1;
  }

  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    padding-top: 6rem;
  }

`
const PageContentInner = styled.div`
  padding-left: ${props => props.theme.paddingSidesMobile};
  padding-right: ${props => props.theme.paddingSidesMobile};
  padding-top: 100px;
  padding-bottom: 40px;
  overflow: hidden;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding-left: ${props => props.theme.paddingSidesDesktop};
    padding-right: ${props => props.theme.paddingSidesDesktop};
    padding-top: 0;
    padding-bottom: 0;
    
  }
`

const CopyContainer = styled.div`

  width: 100%;
  
  h1 {
  }
  
  p {
    color: ${props => props.theme.blueDark};
    line-height: 1.75;
    margin-bottom: 0;
  }
  
  h5 {
    color: ${props => props.theme.blueMed};
    font-weight: 300;
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.5;
  }
  
  img {
    display: none;
  }
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
      width: 100%;
      margin-top: 8rem;
      
      p {
        font-size: 1.1rem;
        margin-bottom: 0;
        width: 80%;
      }
      
      h5 {
        margin-top: 0;
        font-size: 1.5rem;
        line-height: 1.6;
        width: 80%;
      }
      
      img {
        position: absolute;
        display: block;
        width: 100%;
        height: auto;
        top: 0rem;
      }
  }

  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    margin-top: 4rem;

    img {
      top: -2rem;
    }
  }

  .bg-person-name {
    width: 22rem;
    position: absolute;
    top: 65rem;
    right: -2rem;
    padding: 0 2rem;
    border-radius: 20px 0 0 20px;
    background-color: white;
    color: ${props => props.theme.blueDark};
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 3.375rem;
    height: 3.375rem;
    z-index: 10;

    @media (min-width: ${props => props.theme.breakpointTablet}) {
      top: 30rem;
      z-index: 1000;
    }

    @media (min-width: ${props => props.theme.breakpointDesktop}) {
      right: 0rem;
    }

    @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
      top: 42rem;
    }

  }
`

const Column = styled.div`

    position: relative;
    display: inline-block;
    vertical-align: top;

    ${CopyContainer} h1 {
      color: ${props => props.theme.blueDark};
      font-weight: 900;
      font-size: 2.1875rem;
      margin-bottom: 5px;
    }
    
    &:first-child {
        z-index: 3;
        margin-bottom: 20rem;
      
        ${CopyContainer} h5 {
            width: 50%;
        }
    }
    
    &:last-child {
        
        ${CopyContainer} h5 {
            width: 70%;
        }
    }
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        
        &:first-child {
            width: 45%;
            
            ${CopyContainer} h5 {
                width: 90%;
                // padding-left: 100px;
            }
            
            ${CopyContainer} p {
                margin: 0.5rem 0rem;
                width: 90%;
                // padding-left: 100px;
            }
        }
        
        &:last-child {
            width: 55%;
            left: ${props => props.theme.paddingSidesDesktop};
            
            ${CopyContainer} h5 {
                padding-left: 0;
                margin-top: 2rem;
                width: 90%;
            }
        }
    }

    @media (min-width: ${props => props.theme.breakpointDesktop}) {
      ${CopyContainer} h1 {
        font-size: 2.875rem;
        margin-bottom: 1rem;
      }
    }

    @media (min-width: ${props => props.theme.breakpointXLDesktop}) {
      &:first-child {
        margin-bottom: 40rem;
      }
    }
`

const BackgroundContent = styled.div`

  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  // height: 760px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: linear-gradient(to right, #cddbe7, #fff);
  overflow: hidden;

  border-bottom-width: 2px;
  border-bottom-color: white;
  border-bottom-style: solid;
  -webkit-box-shadow: 0 0 30px 10px rgba(151,200,230,1);
  box-shadow: 0 0 30px 10px rgba(151,200,230,1);
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center 100%;
    background-size: 100% auto;
    background-image: url(${model_mbl});
  }

  
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    
    height: 100%;
    
    &:after {
      display: none;
    }
  }
  
  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    top: 0rem;
    height: 100%;

  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    top: 0rem;
    
  }

  @media (min-width: ${props => props.theme.breakpointXLDesktop}) {
    
  }
`
const SolutionsModuleContainer = styled.div`
  width: 100%;
  height: 335px;
  min-height: 335px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  
  -webkit-box-shadow: 3px 3px 12px 0 #8FB4D9;
  box-shadow: 3px 3px 12px 0 #8FB4D9;
  
  transition: height .5s, max-height .5s;
  
  &.resultsView {
    height: 445px;
  }
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    margin-top: 2rem;
    width: 100%;
    
    transition: width 1s, min-height 1s;
  
    &.resultsView {
      width: 100%;
      height: auto;
      min-height: 335px;
      max-height: 500%;
    }
  }

  @media (min-width: 320px) and (max-width: 767.5px) {
    margin: 3rem 0rem;
    width: 100%;
    
    // transition: width .5s, min-height .5s;
  
    &.resultsView {
      width: 23rem;
      height: auto;
      min-height: 335px;
      max-height: 500%;
    }
  }
`


class IndexPage extends React.Component {

  state = {
    showingResults: false,
    currentView: '',
    matchMediaInstance: undefined,
  }

  componentDidMount() {

    let instance = window.matchMedia('(max-width: 767px)')
    if (instance.addEventListener) {
      instance.addEventListener("change", this.doMatchBreakPoint)
    } else {
      instance.addListener(this.doMatchBreakPoint)
    }

    this.doMatchBreakPoint(instance)

    this.setState({
      matchMediaInstance: instance,
    })
  }

  componentWillUnmount() {
    // removing the matchMedia listener prevent lingering references that cause memory leaks
    // have to check for removeEventListener or removeListener for browser compatibility
    if (this.state.matchMediaInstance.removeEventListener) {
      this.state.matchMediaInstance.removeEventListener("change", this.doMatchBreakPoint)
    } else {
      this.state.matchMediaInstance.removeListener(this.doMatchBreakPoint)
    }
  }

  doMatchBreakPoint = (e) => {
    this.setState(state => {
      // store a flag to determine what breakpoint we are on
      state.currentView = e.matches ? 'mobile' : 'desktop'
    }, () => {
      this.setState({state: this.state})
    })
  }

  doResultsView = () => {
    this.setState(state => {
      state.showingResults = true;
    }, () => {
      // forcing a rerender after state has been updated with new menus
      this.setState({ state: this.state });
    })
  }


  doStartOver = () => {
    this.setState(state => {
      state.showingResults = false;
    }, () => {
      // forcing a rerender after state has been updated with new menus
      this.setState({ state: this.state });
    })
  }

    render() {

        return (
            <PageTemplate metaDataIndex={2} selectedNavIndex={2}>
                <GlobalStyles />
                <PageContent>
                    <BackgroundContent>
                    </BackgroundContent>
                    <PageContentInner role="main" id="main">
                        <Column>
                            <CopyContainer>
                                <h1>The Difference Is the Range of Expertise and Talent</h1>
                                <p>Our strength lies in our differences and in our depth of talent and personalities that create compelling, award-winning work.
                                </p>
                                <p>
                                How can we make a difference for you? Search by category, specialty, and location to find an agency.</p>
                            </CopyContainer>
                            <SolutionsModuleContainer className={this.state.showingResults ? 'resultsView' : ''}>
                              <SolutionsModule agencyData={this.props.data.allAgencydataXlsxSheet1.edges} agencyLocationsData={this.props.data.allAgencydataXlsxSheet2.edges} resultsViewCallback={this.doResultsView} startoverCallback={this.doStartOver} />
                             </SolutionsModuleContainer>
                        </Column>
                        <Column>
                            <CopyContainer>
                                <img src={employee_photo} alt='' />
                                <div className='bg-person-name'>
                                Toroa, McCann, New York City, US 
                                </div>
                            </CopyContainer>
                        </Column>
                    </PageContentInner>
                </PageContent>
            </PageTemplate>
        )
    }
}

export default IndexPage
