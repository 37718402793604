import React from 'react'
import styled from 'styled-components'


const StepsList = styled.ul`
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const Step = styled.li`
  width: 23%;
  height: 5px;
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.grayMed};
    opacity: .5;
    transition: opacity .25s;
  }
  
  &.current {
    &:after {
      opacity: 1;
    }
  }
`

class CurrentStepDisplay extends React.Component {

  render() {

    return (
      <StepsList>
        {
          Array.from(Array(this.props.totalSteps).keys()).map((step, index) => {
            return <Step key={index} className={this.props.currentStep == index+1 ? 'current' : ''}></Step>
          })
        }
      </StepsList>
    )
  }
}

export default CurrentStepDisplay