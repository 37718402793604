import React from 'react'
import styled from 'styled-components'


const ResultContainer = styled.div`
  order: 0;
  //flex: 1 1 auto;
  align-self: auto;
  text-align: center;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  width: 100%;
  margin-top: 3%;
  padding: 0 24px;

  @media (min-width: 300px) {
    padding: 0rem;
    width: 100%;
  }
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding: 30px 10px;
    width: 65%;
  }
  
  @media (min-width: 950px) {
    padding: 30px 10px;
    width: 50%;
  }
  
  @media (min-width: 1250px) {
    padding: 30px 10px;
    width: 33%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
    width: 17%; 
 }
`
const AgencyLogo = styled.img`
  display: block;
  margin: 0 auto;
  width: auto;
  height: 60px;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    
    width: 100%;
    height: auto;
    max-width: 255px;
  }
`
const HeadlineAndLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    
    height: 55%;
  }
`
const AgencyLink = styled.a`
  display: block;
  width: 65%;
  margin: 1rem auto 0.5rem auto;
  border-radius: 4px;
  padding: 4px 5%;
  background-color: ${props => props.theme.blueLight};
  color: ${props => props.theme.blueDark};
  font-size: 1rem;
  text-transform: uppercase;
`


class AgencyResultModule extends React.Component {

    render() {
      console.log(this.props);
        return (
            <ResultContainer>
                <AgencyLogo src={this.props.logo} alt='Agency logo' />
                <HeadlineAndLink>
                  {/* <AgencyHeadline>{this.props.headline}</AgencyHeadline> */}
                  <AgencyLink href={this.props.link} target='_blank' rel='noopener noreferrer'>Visit Site</AgencyLink>
                  {/* <a href={"mailto:" + this.props.contactEmail}>
                  <AgencyContact>
                    {this.props.contactName}<br/>
                    <AgencyEmail>{this.props.contactEmail}</AgencyEmail>
                  </AgencyContact>
                  </a> */}
                </HeadlineAndLink>
            </ResultContainer>
        )
    }
}

export default AgencyResultModule
