import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'


import MenuToggleIcon from '../images/icon_menuToggle.png'
import AddMenuButtonIcon from '../images/icon_addMenu.png'

const Container = styled.div`

    padding: 0 15px;
    
    h1 {
        font-size: 1.5rem;
        margin-bottom: .65rem;
        color: ${props => props.theme.grayDark}
    }
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        //padding: 0;
           
        h1 {
            font-size: 2rem;
        }
    }
    
`
const MenusList = styled.ul`
    list-style-type: none;
    
    li {
        margin-bottom: 5px;
    }
    
    .ui.selection.dropdown {
        background-color: ${props => props.theme.blueMed} ! important;
        color: white ! important;
        padding: 8px ! important;
        min-height: 1.5rem ! important;
        border: none ! important;
        
        i.dropdown.icon {
            padding: 0 !important;
            margin: 0  !important;
            opacity: 1 !important;
            border-radius: 50%;
            top: 0 !important;
            right: .5rem !important;
            width: 1.5rem !important;
            height: 100% !important;
            
            &:before {
                content: '' !important;
                display: block !important;
                position: relative !important;
                top: 0 !important;
                left: 0 !important;
                width: 100% !important;
                height: 100% !important;
                background-image: url(${MenuToggleIcon}) !important;
                background-size: 90% auto !important;
                background-position: center !important;
                background-repeat: no-repeat !important;
                transition: transform 0.4s;
            }
        }
        
        .menu {
            background-color: ${props => props.theme.blueLight};
            margin-top: 4px;
            border: none !important;
            
            .item {
                color: ${props => props.theme.blueMed};
            }
        }
        
        &.active.visible {
            i.dropdown.icon {
                &:before {
                    transform: rotate(180deg);
                }
            }
        }
    }
`
const AddMenuButton = styled.button`
    border: none;
    background-color: transparent;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${AddMenuButtonIcon});
`

class DropdownGroup extends React.Component {

    state = {
        completeMenuList: this.props.options,
        currentMenus: [],
        menusNumMax: this.props.menusNumMax,
        header: this.props.header,
    }

    componentDidMount() {
        this.doAddMenu()
    }

    addMenu = (data) => {

        this.setState(({currentMenus}) => {
            let name = `menu_${currentMenus.length+1}`
            currentMenus.push({
                value: '',
                name: name,
                menu: <Dropdown fluid selection upward={false}
                                onChange={this.handleMenuChange}
                                name={name}
                                options={
                                    data.map(item => {
                                        return {
                                            key: item,
                                            text: item,
                                            value: item
                                        }
                                    })
                                }
                                placeholder={this.props.defaultMenusLabel}
                      />
            })

        }, () => {
            // forcing a rerender after state has been updated with new menus
            this.setState({ state: this.state });

        })
    }

    doAddMenu = () => {
        // call the addMenu function and pass the list of options
        this.addMenu(this.state.completeMenuList)
    }

    // handle any change in any of the drop dow menus
    handleMenuChange = (e, selectionObj) => {

        // iterate through the latest list of drop down menu objects
        this.state.currentMenus.forEach((menuObj, index) => {
            // check if the name of the currently selected drop down menu
            // matches the name of the drop menu we are iterating over
            if (selectionObj.name === menuObj.name) {
                // take the value of the selected item of this drop down menu
                // and store it in the corresponding drop down menu object in the state
                this.setState(state => {
                    state.currentMenus[index].value = selectionObj.value
                }, () => {
                    // get an array of all the current selections for all the existing menus
                    let filteredSelections = this.state.currentMenus.map(menuObj => menuObj.value)
                    // filter out
                    // filteredSelections = Array.from(new Set(filteredSelections))
                    // call the callback function of the parent component
                    // and pass it the current list of user menu selections
                    this.props.onChange(filteredSelections)
                })
            }
        })

    }

    render() {

        // on every rerender, get latest set of drop down menus from the state
        const menus = this.state.currentMenus.map(menuOptions => {
            return <li key={menuOptions.name}>
                        {menuOptions.menu}
                   </li>
        })

        return (
            <Container>
                <h1>{this.state.header}</h1>
                <MenusList>{menus}</MenusList>
                {
                    // check if we've reached the maximum number of menus
                    // allowed to see if we should render an 'add menu' button
                    //  this.state.currentMenus.length < this.state.menusNumMax && <AddMenuButton onClick={this.doAddMenu} title='Add Menu' />
                }
            </Container>
        )
    }
}

export default DropdownGroup
