import React from 'react'
import Slider from "react-slick";
import styled from 'styled-components'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PrevNextIcon from '../images/icon_button_prevNext.png'


const SliderContainer = styled.div`
  
  position: relative;
  width: auto;
  top: 15px;
  
  .slick-slider {
    width: 100%;
    margin-bottom: 0.5rem;
    
    .slick-prev, .slick-next {
      
      width: 12px;
      height: 18px;
      z-index: 100;
      
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center center;
        background-image: url(${PrevNextIcon});
      }
    }
    
    .slick-prev {
      left: 4px;
      
      &:before {
        transform: rotate(180deg);
      }
    }
    
    .slick-next {
      right: 4px;
    }
  }
  
  @media (max-width: 767px) {
    width: 25%;
  }
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    display: flex;
    flex-wrap: wrap;
    top: 0;
    padding-bottom: 90px;
    min-width: 25%;
    justify-content: center;
    justify-content: space-evenly;
    //flex: none !important; // putting this here due to IE bug
  }
  
`
const CurrentSlideDisplay = styled.p`
  
  text-align: center;
  color: ${props => props.theme.grayMed};
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    display: none;
  }
`

class SolutionsModuleResults extends React.Component {

  state = {
    settings: {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (index) => {
        this.doShowCurrentSlideIndex(index)
      }
    },
    slidesJsx: this.props.slides || [],
    currentSlide: 1,
    mobileView: '',
    matchMediaInstance: undefined
  };

  componentDidMount() {
    // assigning a handler to handle the triggering of the breakpoint
    let instance = matchMedia('(max-width: 767px)')
    //instance.addEventListener("change", this.doMatchBreakPoint)

    if (instance.addEventListener) {
      instance.addEventListener("change", this.doMatchBreakPoint)
    } else {
      instance.addListener(this.doMatchBreakPoint)
    }

    this.doMatchBreakPoint(instance)

    this.setState({
      matchMediaInstance: instance
    });
  }

  componentWillUnmount() {
    // removing the matchMedia listener to prevent lingering references that cause memory leaks
    //this.state.matchMediaInstance.removeEventListener("change", this.doMatchBreakPoint)

    if (this.state.matchMediaInstance.removeEventListener) {
      this.state.matchMediaInstance.removeEventListener("change", this.doMatchBreakPoint)
    } else {
      this.state.matchMediaInstance.removeListener(this.doMatchBreakPoint)
    }
  }

  doMatchBreakPoint = (e) => {

    this.setState(state => {
      state.mobileView = e.matches
      state.currentSlide = 1
    }, () => {
      // forcing a rerender after state has been updated with new menus
      this.setState({ state: this.state });
    })
  }

  doShowCurrentSlideIndex = (slideIndex) => {

    this.setState(state => {
      state.currentSlide = slideIndex+1
    }, () => {
      this.setState({ state: this.state });
    })
  }

  render() {

    return (
      <SliderContainer>
        {
          this.state.mobileView ?
          <>
            <Slider {...this.state.settings}>
              {
                this.state.slidesJsx.map(result => {
                  return result
                })
              }
            </Slider>
            <CurrentSlideDisplay>{`${this.state.currentSlide} of ${this.state.slidesJsx.length}`}</CurrentSlideDisplay>
          </> :
          this.state.slidesJsx.map(slide => {
            return slide
          })
        }
      </SliderContainer>
    )
  }
}

export default SolutionsModuleResults