import React from 'react'
import styled from 'styled-components'

import DropdownGroup from '../components/DropdownGroup'
import AgencyResultModule from '../components/AgencyResultModule'
import SolutionsModuleResults from '../components/SolutionsModuleResults'
import ContactFormOverlay from "./ContactFormOverlay"
import CurrentStepDisplay from './CurrentStepDisplay'


const ModuleHeader = styled.h1`
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 900;
    color: white;
    text-align: center;
    padding: 12px;
    margin-bottom: 5px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#76bbe7+0,003969+100 */
    background: #76bbe7; /* Old browsers */
    background: -moz-linear-gradient(left,  #76bbe7 0%, #003969 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #76bbe7 0%,#003969 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #76bbe7 0%,#003969 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#76bbe7', endColorstr='#003969',GradientType=1 ); /* IE6-9 */
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {    
        font-size: 1em;
    }
`
const ModuleContent = styled.div`
    position: relative;
    margin: 0;
    height: 285px;
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {    
        height: 83%;
    }
`
const ModuleContentColumns = styled.div`
    position: relative;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: flex-start;
    align-items: flex-start;
    
    transition: left 0.5s;
    
    &.step-0-max-0 {
        width: 100% !important;
        left: 0% !important;
    }
    
    &.step-1-max-1 {
        width: 200% !important;
        left: -100% !important;
    }
    
    &.step-2-max-2 {
        width: 300% !important;
        left: -200% !important;
    }
    
     &.step-0-max-1 {
        width: 200% !important;
        left: 0% !important;
    }
    
     &.step-0-max-2 {
        width: 300% !important;
        left: 0% !important;
    }
    
     &.step-1-max-2 {
         width: 300% !important;
         left: -100% !important;
    }
   
    &.step-3-max-2 {
        width: 400% !important;
        left: -300% !important;
    }
    
    > div {
        order: 0;
        flex: 1;
        align-self: auto;
    }
`
const NextResultsButton = styled.button`
    position: absolute;
    bottom: 22px;
    right: 10px;
    border: none;
    background-color: transparent;
    color: ${props => props.theme.blueMed};
    text-transform: uppercase;
    font-weight: 900;
    cursor: pointer;
    
    &.disabled {
        opacity: .35;
        pointer-events: none;
    }
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        bottom: 20=7px;
    }
`

const BackResultsButton = styled.button`
    position: absolute;
    bottom: 22px;
    left: 9px;
    border: none;
    background-color: transparent;
    color: ${props => props.theme.blueMed};
    text-transform: uppercase;
    font-weight: 900;
    cursor: pointer;
    
    &.disabled {
        opacity: .35;
        pointer-events: none;
    }
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        bottom: 27px;
    }
`


const ResultsEmailLockup = styled.div`
    display:none;
`
const StartOverButton = styled.button`
    position: absolute;
    top: 4px;
    left: 5px;
    border: none;
    background-color: transparent;
    color: ${props => props.theme.blueMed};
    text-transform: uppercase;
    font-weight: 900;
    font-size: .9rem;
    cursor: pointer;
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {  
        font-size: 1.2rem;
        left: 10px;
        top: 10px;
    }
`
const CurrentStepContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 350px;
    padding: 0 15px;
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
        padding: 0;
        left: 15px;
        bottom: 15px;
        width: 70%;
        max-width: 295px;
    }
`

const SelectionNotRequired = styled.div`
    position: absolute;
    bottom: 50px;
    left: 14px;
    font-size: 14px;
    color: #6a737b;
`


class SolutionsModule extends React.Component {

    state = {
        loadedAgencyData: [],
        loadedAgencyLocationsData: [],
        defautAgencyData: [],
        filteredAgencyData: [],
        filteredAgencyDataBySpecialty: [],
        completeMenusLists: {
            categories: [],
            disciplines: [],
            locations: [],
        },
        menusStepNames: [
            'categories',
            'disciplines',
            'locations'
        ],
        menusNumMax: [4,4,4],
        menuLabels: [
            'Select a category',
            'Select a discipline',
            'Select a location'
        ],
        menuGroupsHeaders: [
            "I'm looking for an agency focused on:",
            "That specializes in:",
            "Located in:"
        ],
        menuGroups: [],
        menuGroupsSelections: [],
        resultsModules: [],
        currentStep: 0,
        maxStepsReached: 0,
        reachedLastStep: true,
        categoryChanged: false,
        resultsViewCallback: this.props.resultsViewCallback,
        startoverCallback: this.props.startoverCallback,
        showingResults: false,
        overlayShowCallback: null,
        changedFilteredSelection: false
    }

    componentDidMount = () => {

        // console.log(this.props.agencyLocationsData)

        // load agency data from excel file and store in a variable
        let excelAgencyData = this.props.agencyData.map(agency => {
            // store agency data into a new object
            let dataObj = {...agency.node}
            // convert certain agency data from string lists to arrays
            dataObj.categories = dataObj.categories.split(';')
            dataObj.disciplines = dataObj.disciplines.split(';')
            //dataObj.locations = dataObj.locations.split(';')
            return dataObj
        })

        let excelAgencyLocationsData = this.props.agencyLocationsData.map(agency => {
            // store agency data into a new object
            let dataObj = {...agency.node}
            // convert certain agency data from string lists to arrays
            //dataObj.categories = dataObj.categories.split(';')
            //dataObj.disciplines = dataObj.disciplines.split(';')
            return dataObj.locations
        })

        // process agency data and store into component state by setting up a promise
        // to resolve once process is complete
        new Promise((resolve, reject) => {

            this.setState(state => {
                state.loadedAgencyData = excelAgencyData
                state.loadedAgencyLocationsData = excelAgencyLocationsData
                state.defautAgencyData = excelAgencyData.filter(agency => ['FCB Health Network', 'McCann Health'].indexOf(agency.name) != -1)
            }, () => {
                // wait for react callback to ensure state has been updated before proceeding

                // generate a consolidated list of all lists
                for (let item in this.state.completeMenusLists) {

                    let menuListItems = []

                    // since we're loading the agency locations differently we don't
                    // need to process it the same way as the other data
                    if (item === 'categories') {

                        // put list of the category we are currently iterating over
                        // of every agency into a single array
                        this.state.loadedAgencyData.forEach(agency => {
                            menuListItems.push(...agency[item])
                        })

                        // remove all duplicates from list
                        // the use of set here is a trick for removing duplicates from an array since Set objects can only have unique items
                        // so we create a new set from an array with duplicates and then convert that clean set back into an array
                        menuListItems = Array.from(new Set(menuListItems))

                        // sort the list alphabetically
                        menuListItems.sort()
                        console.log(menuListItems);
                    }

                    if (item === 'disciplines') {

                        // put list of the category we are currently iterating over
                        // of every agency into a single array
                        this.state.loadedAgencyData.forEach(agency => {
                            agency[item].forEach(discipline => {
                                if (discipline !== "NULL") {
                                    return menuListItems.push(...agency[item]);
                                }
                            })


                        })

                        // remove all duplicates from list
                        // the use of set here is a trick for removing duplicates from an array since Set objects can only have unique items
                        // so we create a new set from an array with duplicates and then convert that clean set back into an array
                        menuListItems = Array.from(new Set(menuListItems))

                        // sort the list alphabetically
                        menuListItems.sort()
                    }

                    if (item === 'locations') {
                        menuListItems = [...this.state.loadedAgencyLocationsData]
                        // remove all duplicates from list
                        menuListItems = Array.from(new Set(menuListItems))
                    }

                    // update the state with the processed lists
                    this.setState( state => {

                        state.completeMenusLists[item] = menuListItems

                    }, () => {
                        // once the set state callback has fired, we know it is safe to resolve the promise
                        // so that we can continue and add our first menu
                        resolve()
                    })
                }

            })

        }).then(() => {
            // add the first menu upon parsing off all data
            this.addMenuGroup()

        }).catch(error => {
            console.log(`Error processing excel data: ${error}`)
        })
    }

    addMenuGroup = () => {
        this.setState(state => {
            const {completeMenusLists, menusStepNames, currentStep, maxStepsReached,addedDropdown, menusNumMax, menuLabels, menuGroupsHeaders, filteredAgencyData} = state
            state.menuGroups[currentStep] = [];
            const menuStepName = menusStepNames[currentStep];
            let options = completeMenusLists[menuStepName];
            if (currentStep > 0) {
                state.filteredAgencyData = (state.filteredAgencyData.length ? state.filteredAgencyData : state.loadedAgencyData).filter(agency =>
                  agency[menusStepNames[currentStep - 1]].some(data => (state.menuGroupsSelections[this.state.currentStep - 1] || []).indexOf(data) !== -1));
            }


            if (state.filteredAgencyData.length){
                options = options.filter(menuItem =>
                  state.filteredAgencyData.some(data =>
                    data[menuStepName].indexOf(menuItem) !== -1));
            }

            if (currentStep === 2 && !state.filteredAgencyData.length) {
                options = options.filter(menuItem =>
                  state.filteredAgencyDataBySpecialty.some(data =>
                    data[menuStepName].indexOf(menuItem) !== -1));
            }

            if(currentStep > state.maxStepsReached) {
                state.maxStepsReached = currentStep;
            }

            let dropdownKey = 'dropdown-menu-group';

            dropdownKey = (function getRandomKey() {
                if (currentStep === 1 &&
                  (currentStep < state.maxStepsReached) &&
                  state.categoryChanged) {
                    return Math.random() // using a random key to ensure react re-renders this component when we start a new search
                } else if(currentStep === 2 &&
                  (currentStep === state.maxStepsReached) &&
                  state.categoryChanged) {
                    if(state.reachedLastStep) {
                        return 'dropdown-menu-group';
                    }
                    else {
                        return Math.random()
                    }
                }
                else if(currentStep === 0 && state.maxStepsReached === 0) {
                    return Math.random() // using a random key to ensure react re-renders this component when we start a new search
                }
                else {
                    return 'dropdown-menu-group'
                }
            })();

            state.menuGroups[currentStep].push(
              <DropdownGroup
                key={dropdownKey}
                options={options}
                defaultMenusLabel={menuLabels[currentStep]}
                menusNumMax={menusNumMax[currentStep]}
                header={menuGroupsHeaders[currentStep]}
                onChange={this.handleMenuChange}
              />
            )
        }, () => {
            // forcing a rerender after state has been updated with new menus
            this.setState({ state: this.state });
        })
    }

    // handle any change in any of the drop down menus of all the menu groups
    handleMenuChange = selectionsList => {

        this.setState(state => {

            if (state.currentStep === 0 && (state.currentStep < state.maxStepsReached)) {
                state.resultsModules = [];
                state.filteredAgencyData = [];
                state.filteredAgencyDataBySpecialty = [];
            }
            // filter out any selections that are empty for menus a user hasn't used
            let filteredSelectionsList = selectionsList.filter(selection => {
                if (selection) return selection
            })
            // filter out any selections that are duplicated if a user selects the same option more than once
            filteredSelectionsList = Array.from(new Set(filteredSelectionsList))
            // add the latest set of option selections to the corresponding array in the state
            state.menuGroupsSelections[this.state.currentStep] = []
            state.menuGroupsSelections[this.state.currentStep].push(...filteredSelectionsList)
            state.loadedAgencyData.filter(agency => {
                for(let i = 0; i < state.menuGroupsSelections[state.currentStep].length; i++) {
                    if(agency.categories.includes(state.menuGroupsSelections[state.currentStep][i])) {
                        state.filteredAgencyDataBySpecialty.push(agency);
                        state.categoryChanged = true;
                        if(state.currentStep < state.maxStepsReached) {
                            state.reachedLastStep = false;
                        }

                    }
                }
            })
        }, () => {
            this.setState({state: this.state})
        })
    }

    doNext = () => {

        this.setState(state => {
            // track what step we are on
            state.currentStep = this.state.currentStep + 1;
        }, () => {
            this.addMenuGroup();

            if((this.state.currentStep === 1 ||
              this.state.currentStep === 2) &&
              this.state.categoryChanged) {
                this.state.categoryChanged = true;
            } else {
                this.state.categoryChanged = false;
            }

        })
    }

    doBack = () => {

        this.setState(state => {
            // track what step we are on
            state.currentStep = this.state.currentStep - 1;
            this.state.categoryChanged = false;
            if (this.state.currentStep === 2 && this.state.maxStepsReached === 2) {
                this.state.reachedLastStep = true;
            }
        }, () => {
            this.setState({state: this.state});
        })
    }




    doShowResults = () => {

        // continue to track what step we are on
        this.setState(state => {
            state.currentStep = this.state.currentStep + 1
        })

        // iterate through the lists of options the user selected and gather the agencies that match the selections
        let filteredAgencies = this.state.loadedAgencyData.filter(agency => {
            if (this.selectionsAndAgencyMatch(agency)) return agency
        })

        // check if we have no results so that we display the default agencies
        // filteredAgencies = filteredAgencies.length == 0 ? [...this.state.defautAgencyData] : filteredAgencies

        console.log('filtered agencies ' + filteredAgencies)

        // we use the temp array to create jsx for components for each of the agencies.
        this.setState(state => {
            console.log(filteredAgencies);
            filteredAgencies.map(agency => {

                state.resultsModules.push(
                  <AgencyResultModule logo={`images/${agency.logo}`} headline={agency.headline} link={agency.link} key={agency.name} contactName={agency.contact_name} contactEmail={agency.contact_email} />
                )
            })

            state.showingResults = true

        }, () => {
            this.setState({state: this.state})
            this.state.resultsViewCallback()
        })
    }

    doStartOver = () => {
        this.setState(state => {
            state.showingResults = false;
            state.currentStep = 0;
            state.resultsModules = [];
            state.menuGroups = [];
            state.menuGroupsSelections = [];
            state.filteredAgencyData = [];
            state.filteredAgencyDataBySpecialty = [];
            state.maxStepsReached = 0;
            state.categoryChanged = false;
            state.reachedLastStep = true;
        }, () => {
            this.doForceUpdateState()
        })
    }

    doForceUpdateState = () => {
        this.setState(state => {
            this.state = state
        }, () => {
            this.addMenuGroup()
            this.state.startoverCallback()
        });
    }

    // check if the agency data passed includes the category and discipline options the user selected
    selectionsAndAgencyMatch = (agencyData) => {

        var match = true

        // iterate over the step names array
        // we use the menuStepNames here because this array is in the same order that
        // the options columns get loaded in from the excel doc which determines how all steps options data is ordered
        for (let i = 0; i < this.state.menusStepNames.length; i++) {
            // check to see that EVERY one of the options the user selected are in the agency's list of options.
            //** NOTE: exclude the disciplines list since we are currently not filtering based on that list **//
            // if (this.state.menusStepNames[i] !== 'disciplines') {
            if(this.state.menuGroupsSelections[i]) {
                match = this.state.menuGroupsSelections[i].some(selection => agencyData[this.state.menusStepNames[i]].indexOf(selection) >= 0)
                if (!match) break
            }

            // }
        }
        console.log('match ' + match);
        return match
    }

    // this function is used to determine what button to display in the solutions engine
    // based on the current step we are on and also the disabled state of the buttons based
    // on whether the user has made a selection from the drop down menus
    buttonToShow = () => {

        if (this.state.currentStep === 0) {
            return <NextResultsButton className={ !this.state.menuGroupsSelections[this.state.currentStep] && 'disabled' } onClick={this.doNext}>Next</NextResultsButton>
        }

        else if (this.state.currentStep < Object.keys(this.state.completeMenusLists).length-1) {
            return (
              <>
                  <NextResultsButton className={ !this.state.menuGroupsSelections[this.state.currentStep] } onClick={this.doNext}>Next</NextResultsButton>
                  <BackResultsButton className={ !this.state.menuGroupsSelections[this.state.currentStep] } onClick={this.doBack}>&lt; Back</BackResultsButton>
                  <SelectionNotRequired>Discipline not required. Click NEXT to continue.</SelectionNotRequired>
              </>
            )
        } else {
            if (!this.state.showingResults) {
                return (
                  <>
                      <NextResultsButton className={ !this.state.menuGroupsSelections[this.state.currentStep] } onClick={this.doShowResults}>Results</NextResultsButton>
                      <BackResultsButton className={ !this.state.menuGroupsSelections[this.state.currentStep] } onClick={this.doBack}>&lt; Back</BackResultsButton>
                      <SelectionNotRequired>Location not required. Click RESULTS to continue.</SelectionNotRequired>
                  </>
                )
            } else {
                return <ResultsEmailLockup><span>delete me</span> 
                <button onClick={this.doShowEmailOverlay}>Email</button></ResultsEmailLockup>
            }
        }

    }

    // doShowEmailOverlay = () => {
    //     this.setState.overlayShowCallback(true)
    // }

    render() {


        return (
          <>
              <ModuleHeader>BEGIN YOUR SEARCH HERE</ModuleHeader>
              <ModuleContent>
                  <ModuleContentColumns className={`step-${this.state.currentStep}-max-${this.state.maxStepsReached}`} style={{width: `${(this.state.currentStep+1) * 100}%`, left: `${this.state.currentStep * -100}%`}}>
                      {
                          // render the required set of drop down menus
                          this.state.menuGroups.map(menuGroup => {
                              return menuGroup
                          })
                      }
                      {
                          this.state.resultsModules.length > 0 ? <SolutionsModuleResults slides={this.state.resultsModules} /> : []
                      }
                  </ModuleContentColumns>
                  {
                      this.state.showingResults && <StartOverButton onClick={this.doStartOver}>{`< Start Over`}</StartOverButton>
                  }
                  {
                      // check to see which step we are on to determine if we should
                      // display either the 'next' button or the 'results' button
                      this.buttonToShow()
                  }
                  {
                      // this component needs to know how many total steps there are so we use
                      // the completeMenusLists state property's length and add one to it for the results step
                      // it can also take the current step to display so we pass it the currentStep state property
                      // console.log(Object.keys(this.state.completeMenusLists).length + 1)
                      <CurrentStepContainer><CurrentStepDisplay totalSteps={Object.keys(this.state.completeMenusLists).length + 1} currentStep={this.state.currentStep+1}></CurrentStepDisplay></CurrentStepContainer>
                  }
              </ModuleContent>

              {
                  this.state.showingResults && <ContactFormOverlay
                    formType='solutions'
                    setShowCallback={(callback) => {
                        this.setState.overlayShowCallback = callback;
                    }}
                    // searchTerms={this.state.menuGroupsSelections.flat()}
                    searchTerms={[].concat.apply([], this.state.menuGroupsSelections)}
                  />
              }
          </>
        )
    }
}

export default SolutionsModule
